import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus.tsx";

import Login from "./login";
import { Dashboard } from "./dashboard";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Overlay } from "./utility/overlay";
import { Ticket } from "./ticket";
import { Error } from "./404";
import Home from "./home";
import { AdminUser } from "./admin/user";
import { AdminNavigation } from "./admin/navigation";
import { MkgProject } from "./mkg/project";
import { MkgBlanketPurchaseOrder } from "./mkg/blanketPurchaseOrder";
import { MkgGemeinkosten } from "./mkg/gemeinkosten";
import { ShirtivalMbwInvoiceUpload } from "./shirtival/mbwInvoiceUpload";
import { User } from "./user/user"; 
import { OnboardingTools } from "./sgbit/onboardingTools";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [allowed, setAllowed] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to track sidebar open/close
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(undefined);

  const [navItems, setNavItems] = useState([]);

  const toggleSidebar = () => {
      setIsSidebarOpen(prevState => !prevState);
      if (!isSidebarOpen && window.innerWidth < 992) {
          setIsOverlayOpen(true);
      } else {
          setIsOverlayOpen(false);
      }
  };

  const toggleOverlay = () => {
      setIsOverlayOpen(prevState => !prevState);
      setIsSidebarOpen(prevState => !prevState);
  };

  const toggleTicket = () => {
      setIsTicketOpen(prevState => !prevState);
  };

  const handleResize = () => {
      if (window.innerWidth >= 992) {
          setIsOverlayOpen(false);
      }
      if (window.innerWidth < 992 && isSidebarOpen) {
          setIsOverlayOpen(true);
      }
  };

  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const user = await AuthService.getCurrentUser();
      if (user) {
        setCurrentUser(user);
        try {
          const logo = await AuthService.getLogo(user);
          setImageUrl(logo);
        } catch (error) {
          setImageUrl('/SGB-logo.png');
        }
        const navItems = await AuthService.getCurrentUserNavigation(user);
        if (Array.isArray(navItems)) {
          const sortedMenuItems = navItems.sort((a, b) => {
            if (a.label === "DASHBOARD") return -1; // DASHBOARD always first
            if (b.label === "DASHBOARD") return 1;
            return a.label.localeCompare(b.label);  // Alphabetical order for others
          });
          setNavItems(sortedMenuItems);
        } else {
          setNavItems([]);
        }
        const response = await UserService.getAllowed(user, location);
        setAllowed(response.allowed);
      } else {
          logOut();
      }
    };
  
    fetchData();
  
    EventBus.on("logout", logOut);
    return () => {
      EventBus.remove("logout");
    };
  }, [location]);

  const logOut = () => {
    AuthService.logout();
    setCurrentUser(undefined);
  };

  useEffect(() => {
    if (!allowed) {
      navigate('/');
    }
  }, [allowed]);

  return (
    <>
      <>
        <Routes>
          <Route exact path="/login" element={<Login />} />
        </Routes>
        {currentUser === undefined ? (<Login />)
        : (
          <><Navigation
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            navItems={navItems}
            imageUrl={imageUrl} 
            logOut={logOut} />
            <div className="w3-main w3-display-container" style={{ marginLeft: '300px' }}>
              <Header
                toggleSidebar={toggleSidebar}
                toggleTicket={toggleTicket}
                currentUser={currentUser} />
              <Overlay
                isOverlayOpen={isOverlayOpen}
                toggleOverlay={toggleOverlay} />
              {isTicketOpen && <Ticket
                toggleTicket={toggleTicket} 
                currentUser={currentUser} />}
              <Routes>
                <Route exact path="/Dashboard" element={<Dashboard />} />
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="" element={<Dashboard />} />
                <Route exact path="/Home" element={<Home />} />
                <Route exact path="/admin/user" element={<AdminUser currentUser={currentUser} />} />
                <Route exact path="/user" element={<User currentUser={currentUser} />} />
                <Route exact path="/admin/navigation" element={<AdminNavigation currentUser={currentUser} />} />
                <Route exact path="/mkg/blanketPurchaseOrder" element={<MkgBlanketPurchaseOrder currentUser={currentUser} />} />
                <Route exact path="/mkg/project" element={<MkgProject currentUser={currentUser} />} />
                <Route exact path="/mkg/gemeinkosten" element={<MkgGemeinkosten currentUser={currentUser} />} />
                <Route exact path="/shirtival/mbwInvoiceUpload" element={<ShirtivalMbwInvoiceUpload currentUser={currentUser} />} />
                <Route exact path="/onboarding/tools" element={<OnboardingTools currentUser={currentUser} />} />
                <Route exact path="/onboarding/preparation" element={<Dashboard/>} />
                <Route path="*" element={<Error />} />
              </Routes>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default App;